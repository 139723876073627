export default [
  {
    id: 'C-0000001',
    date: '19/06/2020',
    item: 'Polo shrit c-5',
    total: 'KS 50,000',
    status: 'Pending',
    shipto: 'No.97, xxxxxxxxx Street, Lanmadaw, Yangon',
    url: '/'
  },
  {
    id: 'C-0000002',
    date: '19/06/2020',
    item: 'Umbrella',
    total: 'KS 50,000',
    status: 'Pending',
    shipto: 'No.97, xxxxxxxxx Street, Lanmadaw, Yangon',
    url: '/'
  },
  {
    id: 'C-0000003',
    date: '19/06/2020',
    item: 'Polo shrit c-20',
    total: 'KS 50,000',
    status: 'Pending',
    shipto: 'No.97, xxxxxxxxx Street, Lanmadaw, Yangon',
    url: '/'
  },
  {
    id: 'C-0000004',
    date: '19/06/2020',
    item: 'Polo Shrit c-15',
    total: 'KS 50,000',
    status: 'Pending',
    shipto: 'No.97, xxxxxxxxx Street, Lanmadaw, Yangon',
    url: '/'
  }
]