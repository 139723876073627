export default {
  data: [
    {
      img: require('../assets/img/category2.jpg'),
      label: 'Polo Shirt',
      patch: '/product/category/shirt'
    },
    {
      img: require('../assets/img/category3.jpg'),
      label: 'Coat',
      patch: '/product/category/shirt'
    },
    {
      img: require('../assets/img/category1.jpg'),
      label: 'Track Suit Pants',
      patch: '/product/category/shirt'
    },
    {
      img: require('../assets/img/category2.jpg'),
      label: 'Polo Shirt',
      patch: '/product/category/shirt'
    },
    {
      img: require('../assets/img/category3.jpg'),
      label: 'Polo Shirt',
      patch: '/product/category/shirt'
    },
    {
      img: require('../assets/img/category1.jpg'),
      label: 'Polo Shirt',
      patch: '/product/category/shirt'
    }
  ]
}