export default [
  {
    "key"   :   "Name",
    "value" :   "John"
  },
  {
    "key"   :   "Email",
    "value" :   "xxxx@xxxx.com"
  },
  {
    "key"   :   "Phone",
    "value" :   "09xxxxxxxxx"
  },
  {
    "key"   :   "Address",
    "value" :   "No.xx, xxxxxxxx Street, Yangon"
  },
  {
    "key"   :   "City",
    "value" :   "Yangon"
  },
  {
    "key"   :   "Township",
    "value" :   "Lanmadaw"
  }
]