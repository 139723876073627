export default {
  title: 'Weekly Promotion',
  data: [
    {
      img: require('../assets/img/promo.png'),
      path: '/'
    },
    {
      img: require('../assets/img/promo.png'),
      path: '/'
    },
    {
      img: require('../assets/img/promo.png'),
      path: '/'
    },
    {
      img: require('../assets/img/promo.png'),
      path: '/'
    }
  ]
}