import { lazy } from "react"
import { Student } from '../layout'
const About = lazy(() => import("../layout/about"));
const NotFound = lazy(() => import("../layout/404"));
const Contactus = lazy(() => import("../layout/contactus"));
const Product = lazy(() => import("../layout/product"));
const Category = lazy(() => import("../layout/category"));
const Signin = lazy(() => import("../layout/signin"));
const ResetPasssword = lazy(() => import("../layout/resetpassword"));
const Verification = lazy(() => import("../layout/verification"));
const ConfirmPassword = lazy(() => import("../layout/confirmpassword"));
const Signup = lazy(() => import("../layout/signup"));
const Profile = lazy(() => import("../layout/profile"));
const EditProfile = lazy(() => import("../layout/editprofile"));
const ChangePassword = lazy(() => import("../layout/changepassword"));
const Home = lazy(() => import("../layout/home"));
const ProductDetail = lazy(() => import("../layout/product-detail"));
const OrderDetail = lazy(() => import("../layout/orderdetail"));
const ProductOrder = lazy(() => import("../layout/product-order"));
const Terms = lazy(() => import("../layout/terms"));
const Policy = lazy(() => import("../layout/policy"));


export default [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/aboutus",
    component: About,
  },
  {
    path: "/contactus",
    component: Contactus,
  },
  {
    path: "/product/category",
    component: Category,
  },
  {
    path: "/product/category/:genere/:cateid",
    component: Product,
  },
  {
    path: "/product/category/:genere/:cateid/:prodid",
    component: ProductDetail,
  },
  {
    path: "/product/order/:step/:genere/:name/:id",
    component: ProductOrder,
  },
  {
    path: "/signin",
    component: Signin,
  },
  {
    path: "/resetpassword",
    component: ResetPasssword,
  },
  {
    path: "/verification",
    component: Verification,
  },
  {
    path: "/confirmpassword",
    component: ConfirmPassword,
  },
  {
    path: "/signup",
    component: Signup,
  },
  {
    path: "/profile/:type",
    component: Profile,
    // protect: true,
  },
  {
    path: "/editprofile",
    component: EditProfile,
    // protect: true,
  },
  {
    path: "/changepassword",
    component: ChangePassword,
    // protect: true,
  },
  {
    path: "/orderdetail/:id",
    component: OrderDetail,
    // protect: true,
  },
  {
    path: "/terms",
    component: Terms,
  },
  {
    path: "/policy",
    component: Policy,
  },
  {
    path: "/student/list",
    component: Student,
  },
  {
    path: "*",
    component: NotFound,
  },
];
