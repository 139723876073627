export const ACT_INIT = 'INIT_STORE'

export const ACT_LOCALE = 'LOCALE'

export const ACT_PRODUCTS = 'PRODUCT'

export const OPEN_POPUP = 'OPEN_POPUP'

export const ACT_USER = 'ACT_USER'

export const ACT_OTPVAL = 'ACT_OTPVAL'

export const ACT_PROFILE = 'ACT_PROFILE'

export const ACT_PRODUCTDETAIL = 'ACT_PRODUCTDETAIL'

export const ACT_MATERIAL = 'ACT_MATERIAL'

export const ACT_COLOR = 'ACT_COLOR'

export const ACT_ORDERLINE = 'ACT_ORDERLINE'

export const init = (payload) => {
  return {
    type: ACT_INIT,
    payload
  }
}

export const locale = (payload) => {
  return {
    type: ACT_LOCALE,
    payload
  }
}

export const product = (payload) => {
  return {
    type: ACT_PRODUCTS,
    payload
  }
}

export const open_popup = (payload) => {
  return {
    type: OPEN_POPUP,
    payload
  }
}

export const user = (payload) => {
  return {
    type: ACT_USER,
    payload
  }
}

export const otpval = (payload) => {
  return {
    type: ACT_OTPVAL,
    payload
  }
}

export const profile = (payload) => {
  return {
    type: ACT_PROFILE,
    payload
  }
}

export const product_detail = (payload) => {
  return {
    type: ACT_PRODUCTDETAIL,
    payload
  }
}

export const material_detail = (payload) => {
  return {
    type: ACT_MATERIAL,
    payload
  }
}

export const color_detail = (payload) => {
  return {
    type: ACT_COLOR,
    payload
  }
}

export const act_order_line = (payload) => {
  return {
    type: ACT_ORDERLINE,
    payload
  }
}