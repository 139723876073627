import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getui } from '../../utils'

export default function Footer() {
  const INIT = useSelector(state => state.INIT)
  const {FOOTERINFO} = INIT
  const phCollect = FOOTERINFO[0].phone
  const emailCollect = FOOTERINFO[0].email
  const {LOCALIZATION} = useSelector(state => state.INIT)
  const lang = useSelector(state => state.LOCALE)
  const Asplit = ({data}) => data.split(',').map(item => <a href={'tel:'+item+''} key={item}>{item}</a>)
  
  return (
    <footer className="footer">
      <section className='section-contact'>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 contact-col">
              <h3>{getui(LOCALIZATION, 'contact_info', lang)}</h3>
              <div className="contact">
                <p>
                  <span className="label">{getui(LOCALIZATION, 'address', lang)}</span>
                  <span>{FOOTERINFO[0].address}</span>
                </p>
              </div>
              <div className="contact">
                <p>
                  <span className="label">{getui(LOCALIZATION, 'phone', lang)}</span>
                  <span className='contact-list'>
                    <Asplit data={phCollect}/>
                  </span>
                </p>
              </div>
              <div className="contact">
                <p>
                  <span className="label">{getui(LOCALIZATION, 'email', lang)}</span>
                  <span className='contact-list email'>
                    <Asplit data={emailCollect}/>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-4 contact-col">
              <h3>{getui(LOCALIZATION, 'payment_type', lang)}</h3>
              <ul className='payment'>
                <li>
                  <img src={require('../../assets/img/master.png')} alt='payment' />
                </li>
                <li>
                  <img src={require('../../assets/img/visa.png')} alt='payment' />
                </li>
                <li>
                  <img src={require('../../assets/img/mpu.png')} alt='payment' />
                </li>
                <li>
                  <img src={require('../../assets/img/jcb.png')} alt='payment' />
                </li>
              </ul>
            </div>
            <div className="col-lg-4 contact-col">
              <h3>{getui(LOCALIZATION, 'links', lang)}</h3>
              <ul className='links'>
                <li><Link to='/'>{getui(LOCALIZATION, 'home', lang)}</Link></li>
                <li><Link to='/product/category'>{getui(LOCALIZATION, 'products', lang)}</Link></li>
                <li><Link to='/aboutus'>{getui(LOCALIZATION, 'about_us', lang)}</Link></li>
                <li><Link to='/contactus'>{getui(LOCALIZATION, 'contact_us', lang)}</Link></li>
                <li><Link to='/terms'>{getui(LOCALIZATION, 'term_condition', lang)}</Link></li>
                <li><Link to='/policy'>{getui(LOCALIZATION, 'privacy', lang)}</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <hr/>
      <section className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>Copyright {new Date().getFullYear()}. Nan Lu Garment And Distribution. All Rights Reserved. Powered by <a href='https://innovixdigital.com/' target='_blank' className='powerby' rel="noopener noreferrer">Innovix Digital</a></p>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}
