import React, { useState, useEffect } from 'react'
import { Facebook, Instagram, Code, List, BulletList } from 'react-content-loader'
import './style.scss'

export const PostLoading = ({type}) => {
  const config = {
    speed: 2,
    width: 400,
    height: 160,
    viewBox: "0 0 400 160",
    backgroundColor: 'rgba(59, 209, 0, .1)',
    foregroundColor: 'rgba(59, 209, 0, .2)'
  }
  switch(type) {
    case 'fb':
      return <Facebook {...config}/>
    case 'ins':
      return <Instagram {...config} />
    case 'code':
      return <Code {...config} />
    case 'li':
      return <List {...config} />
    default: 
      return <BulletList {...config} />
  }
}
export default function Loading() {
  const [state, setstate] = useState('')
  useEffect(() => {
    let timer = setTimeout(function() {
      setstate(
        state.length > 2 ? '' : state + '.'
      )
    }, 600)
    return clearTimeout(timer)
  }, [state])
  return (
    <div className="loader">
      <img src={require('../../assets/img/Loading_icon.gif')} alt="sbloading"/>
      {/* <p className="text-secondary">loading data <span className="loading-text"> {state}</span></p> */}
    </div>
  )
}
