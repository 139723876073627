export default {
  title: 'Product Category',
  data: [
    {
      name: 'Product Name',
      img: require('../assets/img/5.png'),
      steps: [
        {
          key: 1,
          value: 'step 1'
        },
        {
          key: 2,
          value: 'step 2'
        },
        {
          key: 3,
          value: 'step 3'
        },
        {
          key: 4,
          value: 'step 4'
        }
      ],
      subcategory: [
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        }
      ]
    },
    {
      name: 'Product Name',
      img: require('../assets/img/5.png'),
      steps: [
        {
          key: 1,
          value: 'step 1'
        },
        {
          key: 2,
          value: 'step 2'
        },
        {
          key: 3,
          value: 'step 3'
        },
        {
          key: 4,
          value: 'step 4'
        }
      ],
      subcategory: [
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        }
      ]
    },
    {
      name: 'Product Name',
      img: require('../assets/img/5.png'),
      steps: [
        {
          key: 1,
          value: 'step 1'
        },
        {
          key: 2,
          value: 'step 2'
        },
        {
          key: 3,
          value: 'step 3'
        },
        {
          key: 4,
          value: 'step 4'
        }
      ],
      subcategory: [
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        }
      ]
    },
    {
      name: 'Product Name',
      img: require('../assets/img/5.png'),
      steps: [
        {
          key: 1,
          value: 'step 1'
        },
        {
          key: 2,
          value: 'step 2'
        },
        {
          key: 3,
          value: 'step 3'
        },
        {
          key: 4,
          value: 'step 4'
        }
      ],
      subcategory: [
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        }
      ]
    },
    {
      name: 'Product Name',
      img: require('../assets/img/5.png'),
      steps: [
        {
          key: 1,
          value: 'step 1'
        },
        {
          key: 2,
          value: 'step 2'
        },
        {
          key: 3,
          value: 'step 3'
        },
        {
          key: 4,
          value: 'step 4'
        }
      ],
      subcategory: [
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        }
      ]
    },
    {
      name: 'Product Name',
      img: require('../assets/img/5.png'),
      steps: [
        {
          key: 1,
          value: 'step 1'
        },
        {
          key: 2,
          value: 'step 2'
        },
        {
          key: 3,
          value: 'step 3'
        },
        {
          key: 4,
          value: 'step 4'
        }
      ],
      subcategory: [
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        },
        {
          name: 'Product Name',
          img: require('../assets/img/5.png'),
          patch: '/'
        }
      ]
    }
  ]
}