export default {
  title: "Hottest Item",
  data: [
    {
      img: require("../assets/img/nanlu.png"),
      label: "Polo Shirt C-5",
      desc: "Lorem ipsum dolor sit amet, sapien etiam",
      patch: "/product/category/shirt/1",
    },
    {
      img: require("../assets/img/nanlu.png"),
      label: "Polo Shirt C-5",
      desc: "Lorem ipsum dolor sit amet, sapien etiam",
      patch: "/product/category/shirt/1",
    },
    {
      img: require("../assets/img/nanlu.png"),
      label: "Polo Shirt C-5",
      desc: "Lorem ipsum dolor sit amet, sapien etiam",
      patch: "/product/category/shirt/1",
    },
    {
      img: require("../assets/img/nanlu.png"),
      label: "Polo Shirt C-5",
      desc: "Lorem ipsum dolor sit amet, sapien etiam",
      patch: "/product/category/shirt/1",
    },
    {
      img: require("../assets/img/nanlu.png"),
      label: "Polo Shirt C-5",
      desc: "Lorem ipsum dolor sit amet, sapien etiam",
      patch: "/product/category/shirt/1",
    },
    {
      img: require("../assets/img/nanlu.png"),
      label: "Polo Shirt C-5",
      desc: "Lorem ipsum dolor sit amet, sapien etiam",
      patch: "/product/category/shirt/1",
    },
    {
      img: require("../assets/img/nanlu.png"),
      label: "Polo Shirt C-5",
      desc: "Lorem ipsum dolor sit amet, sapien etiam",
      patch: "/product/category/shirt/1",
    },
    {
      img: require("../assets/img/nanlu.png"),
      label: "Polo Shirt C-5",
      desc: "Lorem ipsum dolor sit amet, sapien etiam",
      patch: "/product/category/shirt/1",
    },
  ],
};
