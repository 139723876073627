export default {
  currency: "KS",
  products: [
    {
      id: 1,
      img: require('../assets/img/product.png'),
      gallery: [
        {
          img: require('../assets/img/product.png')
        }
      ],
      color: [
        "red",
        "blue",
        "green"
      ],
      size: [
        "large",
        "medium",
        "small"
      ],
      name: "Baby Toy",
      desc: "Est incididunt nisi labore velit in irure labore culpa eu et qui. Officia voluptate ut nostrud minim id irure laboris enim cillum duis veniam amet. Commodo sit nulla laborum consectetur commodo incididunt adipisicing et dolore aliquip labore sint pariatur. Consectetur aute dolore anim minim. Lorem id ex in dolor cupidatat consectetur irure aliqua et occaecat adipisicing quis fugiat commodo. Ullamco fugiat aute eiusmod minim duis. Voluptate esse voluptate dolor ut ullamco consequat enim mollit sunt.",
      quantity: 3,
      price: 30000
    },
    {
      id: 1,
      img: require('../assets/img/product.png'),
      gallery: [
        {
          img: require('../assets/img/product.png')
        }
      ],
      color: [
        "red",
        "blue",
        "green"
      ],
      size: [
        "large",
        "medium",
        "small"
      ],
      name: "Baby Toy",
      desc: "Est incididunt nisi labore velit in irure labore culpa eu et qui. Officia voluptate ut nostrud minim id irure laboris enim cillum duis veniam amet. Commodo sit nulla laborum consectetur commodo incididunt adipisicing et dolore aliquip labore sint pariatur. Consectetur aute dolore anim minim. Lorem id ex in dolor cupidatat consectetur irure aliqua et occaecat adipisicing quis fugiat commodo. Ullamco fugiat aute eiusmod minim duis. Voluptate esse voluptate dolor ut ullamco consequat enim mollit sunt.",
      quantity: 3,
      price: 30000
    },
    
  ],
  subtotal: 30000,
  taxes: 0,
  total: 30000
}