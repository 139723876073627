export default [
    {
        name: 'Latest Products',
        path: '/gallery/latest_items'
    },
    {
        name: 'Weekly Promotion',
        path: '/gallery/may_like'
    },
    {
        name: 'Special Discount',
        path: '/gallery/hp_laptop'
    },
    {
        name: 'Hottest Items',
        path: '/gallery/hottest_items'
    },
    {
        name: 'contact Us',
        path: '/contactus'
    },
    {
        name: 'SIGN IN',
        path: '/'
    },
    {
        name: 'SIGN Up',
        path: '/'
    }
]