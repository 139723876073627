import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { user, profile } from '../../store/actions'
// components
import data from "../../data";
import { getui } from '../../utils'
const { general } = data;

export default function PriNav({prodCate}) {
  const dispatch = useDispatch()
  const [signed, setSigned] = useState(Boolean(localStorage.getItem("signin")));
  const history = useHistory();
  const location = useLocation();
  const profile_info = useSelector(state => state.PROFILE)
  const {LOCALIZATION} = useSelector(state => state.INIT)
  const lang = useSelector(state => state.LOCALE)

  
  const Logout = () => {
    dispatch(profile(null))
    dispatch(user(null))
    localStorage.clear();
    history.replace({ pathname: "/" });
  };

  useEffect(() => {
    setSigned(Boolean(localStorage.getItem("signin")));
  }, [location]);
  
  return (
    <nav className="pc-prinav">
      <div className="container">
        <div className="row pc-prinav-row">
          <div className="col-lg-3">
            <figure className="logo">
              <Link to="/">
                <img src={general.logo} alt={general.appname} />
              </Link>
            </figure>
          </div>
          <div className="col-lg-9 nav-wrap">
            <ul className="nav-link">
              <li className="nav-item">
                <NavLink
                  exact
                  to="/"
                  className="nav-item-link"
                  activeClassName="active"
                >
                  {getui(LOCALIZATION, 'home', lang)}
                </NavLink>
              </li>
              <li className="nav-item mega-menu">
                <NavLink
                  to="/product/category"
                  className="nav-item-link"
                  activeClassName="active"
                >
                  {getui(LOCALIZATION, 'products', lang)}
                </NavLink>
                <div className="mega-menu-wrap">
                  <div className="mega-menu-row">
                    { 
                      prodCate.filter(prod => prod.cat_code === 'adult').map((info, key) => 
                        <React.Fragment key={key}>
                          <div className="mega-menu-column">
                            <h5>{info.category_name}</h5>
                            <ul className="mega-item-wrap first-mega">
                              {
                                info.sub_category.map((subinfo, key) => 
                                <li className="mega-item" key={key}>
                                  <Link
                                    to={`/product/category/${info.cat_code}/${subinfo.category_id}`}
                                    className="item-link"
                                  >
                                    {subinfo.category_name}
                                  </Link>
                                </li>
                                )
                              }
                            </ul>
                          </div>
                        </React.Fragment>
                      )
                    }
                    { 
                      prodCate.filter(prod => prod.cat_code === 'child').map((info, key) => 
                        <React.Fragment key={key}>
                          <div className="mega-menu-column">
                            <h5>{info.category_name}</h5>
                            <ul className="mega-item-wrap first-mega">
                              {
                                info.sub_category.map((subinfo, key) => 
                                <li className="mega-item" key={key}>
                                  <Link
                                    to={`/product/category/${info.cat_code}/${subinfo.category_id}`}
                                    className="item-link"
                                  >
                                    {subinfo.category_name}
                                  </Link>
                                </li>
                                )
                              }
                            </ul>
                          </div>
                        </React.Fragment>
                      )
                    }
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/aboutus"
                  className="nav-item-link"
                  activeClassName="active"
                >
                  {getui(LOCALIZATION, 'about_us', lang)}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contactus"
                  className="nav-item-link"
                  activeClassName="active"
                >
                  {getui(LOCALIZATION, 'contact_us', lang)}
                </NavLink>
              </li>
              {!signed && (
                <>
                  <li className="nav-item">
                    <NavLink
                      to="/signin"
                      className="nav-item-link"
                      activeClassName="active"
                    >
                      {getui(LOCALIZATION, 'sign_in', lang)}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/signup"
                      className="nav-item-link"
                      activeClassName="active"
                    >
                      {getui(LOCALIZATION, 'sign_up', lang)}
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            {signed && (
              <div className="profile">
                <p className="name">
                  <img src={require("../../assets/img/user.svg")} alt="human" />
                  <span>{profile_info !== null && profile_info.name}</span>
                </p>
                <div className="profile-popup">
                  <ul className="profile-item">
                    <li>
                      <Link to="/profile/profile">{getui(LOCALIZATION, 'profile', lang)}</Link>
                    </li>
                    <li onClick={Logout}>{getui(LOCALIZATION, 'logout', lang)}</li>
                  </ul>
                </div>
              </div>
            )}
            <figure className="menu-bugger">
              <img
                src={require("../../assets/img/menu.png")}
                alt="nanlu open menu"
              />
            </figure>
          </div>
        </div>
      </div>
    </nav>
  );
}
