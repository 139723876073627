import React from 'react'
import { useSelector } from 'react-redux'
// import { open_popup } from '../../redux-actions/action'
// import Button from '../../components/button'
export default function Popup({content}) {
  // const dispatch = useDispatch()
  const state = useSelector(state => state.POPUP)
  return (
    <div 
    className={"custom-popup " + (state === false && " d-none")}
    >
      {
        state !== false && state.content
      }
      {/* <div 
      onClick={() => {
        document.body.classList.remove('popup-body')
        dispatch(open_popup(false))
      }}
      className="custom-popup-close"></div>
      {state !== false && 
      <div className="popup-content">
        <img 
        alt='check'
        src={require('../../assets/img/correct.svg')} 
        className="correct"/>
        {state.content}
        <Button 
          addclass='close-popup'
          text="OK"
          play={() => {
            document.body.classList.remove('popup-body')
            dispatch(open_popup(false))}}
        />
      </div>} */}
    </div>
  )
}
