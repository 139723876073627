const ENDPOINTS = {
  BASE_URL: 'http://nanlu.innovix-solutions.net',
  // BASE_URL: 'http://admin.nanlucompany.com',
  PATH: {
    USER: {
      STATE_LIST: 'get#/list/state',
      CUSTOMER_CITY: 'get#/api/customer/city',
      SIGN_UP: 'post#/user/signup',
      OTP_VERIFY: 'post#/user/otp_verify',
      OTP_RESEND: 'post#/otp/resend',
      LOG_IN: 'get#/api/auth/token',
      LOG_OUT: 'delete#/api/auth/token',
      RESET_PASSWORD: 'post#/user/reset_password',
      FORGET_PASSWORD: 'post#/user/forget_password',
      USER_CHECK: 'post#/user/check',
      MOBILE_CHECK: 'get#/user/mobile_check',
      GET_PROFILE: 'get#/get/profile',
      EDIT_PROFILE: 'post#/save/profile/information',
      CONTACT_INFO: 'post#/save/contactinformation'
    },
    PRODUCT: {
      MATERIAL: 'get#/product/material/list',
      COLOR: 'get#/color/list',
      SIZES: 'get#/product/size/list',
      PRODUCTS: 'get#/list/product',
      PRODUCT: 'get#/product/',
      IS_PREORDER: 'get#/product/type',
      PRE_ORDER: 'post#/preorder/create',
      GALLERY: 'get#/list/website/gallery',
      GALLERY_DETAIL: 'get#/list/website/gallery/detail',
      PRICELIST: 'get#/product/pricelist/price',
      SALE_ORDER_DETAIL: 'get#/sale_order/detail',
      SALE_ORDER: 'get#/saleorder'
    },
    GENERAL: {
      ABOUT_US: 'get#/webpage/aboutus',
      SLIDER: 'get#/website/slider',
      LOCALE: 'get#/ui_translation/list',
      CATEGORIES: 'get#/product/categories',
      PAYMENT_LIST: 'get#/payment/list',
      DELIVERY_RULE: 'get#/delivery/rules',
      GALLERY: 'get#/list/website/gallery',
      TERMS: 'get#/terms/condition',
      POLICY: 'get#/privacy/policy',
      FOOTER_INFO: 'get#/general_info',
      DELIVERY_INFO: 'get#/api/delivery/information'
    }
  }
}

export {ENDPOINTS}