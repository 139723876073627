// react module
import React from 'react';
import ReactDOM from 'react-dom';
// redux module
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'
// custom module
import App from './App';
import * as serviceWorker from './serviceWorker';
import { REDUCERS } from './store/reducer';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';

// const store = createStore(REDUCERS, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
const store = createStore(REDUCERS, composeWithDevTools(applyMiddleware(thunk)))

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
