
export default [
  {
    name: 'facebook',
    url: '/',
    img: require('../assets/img/facebook.svg')
  },
  {
    name: 'twitter',
    url: '/',
    img: require('../assets/img/twitter.svg')
  },
  {
    name: 'instagram',
    url: '/',
    img: require('../assets/img/instagram.svg')
  }
]