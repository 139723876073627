import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { locale } from '../../store/actions'
const _ = require('underscore')
export default function SecNav() {
  const dispatch = useDispatch()
  const GET_LANG = useSelector(state => state.LOCALE)
  const LANG_SWITCH = [
    {
      key: 'en',
      val: 'English'
    },
    {
      key: 'my',
      val: 'Myanmar'
    }
  ]
  const HANDLE_LANG = val => {
    dispatch(locale(val))
  }

  const SelectedLang = () => <p>{_.find(LANG_SWITCH, item => item.key === GET_LANG)?.val}</p>
  const LangList = () => LANG_SWITCH.map(({key, val}) => 
    <li key={key}>
      <p className={key} onClick={() => HANDLE_LANG(key)}>{val}</p>
    </li>
  )

  return (
    <React.Fragment>
      <nav className="pc-secnav">
        <div className="container">
          <div className="ph-wrap">
            <img src={require('../../assets/img/phone.svg')} alt='phone' />
            <a href='tel:+959960109000'>+95 9 960109000</a>
          </div>
          <div className="pc-option">
            <SelectedLang />
            <ul>
              <LangList />
            </ul>
          </div>
        </div>
      </nav>

    </React.Fragment>
  )
}
