import _ from 'underscore'
import {API, ENDPOINTS} from '../apis'
const { PRODUCTS, GALLERY_DETAIL, PRODUCT, MATERIAL, COLOR, PRICELIST, PRE_ORDER, SALE_ORDER_DETAIL, SALE_ORDER } = ENDPOINTS.PATH.PRODUCT

const galleryFilter = data => {
  return _.map(data, ({description, product_image, name, product_tmpl_id, categories, product_id}) => {
    return {
      img: product_image,
      label: name,
      desc: description,
      prod_id: product_tmpl_id || product_id,
      cate_id: categories[0].id
    }
  })
}
export const CTRL_PRODUCTS = data => API(PRODUCTS, data).then(({data}) => galleryFilter(data.result.product_list))

export const CTRL_GALLERY_DETAIL = data => API(GALLERY_DETAIL, data).then(({data}) => galleryFilter(data.result))

export const PRODUCT_DETAIL = data => API(PRODUCT, data).then(({data}) => data)

export const CTRL_MATERIAL = data => API(MATERIAL, data).then(({data}) => data)

export const CTRL_COLOR = data => API(COLOR, data).then(({data}) => data)

export const CTRL_PRICELIST = data => API(PRICELIST, data).then(({data}) => data)

export const CTRL_PRE_ORDER = (data, headers) => API(PRE_ORDER, data, headers).then(({data}) => data)

export const CTRL_SALE_ORDER_DETAIL = (data, headers) => API(SALE_ORDER_DETAIL, data, headers).then(({data}) => data)

export const CTRL_SALE_ORDER = (data, headers) => API(SALE_ORDER, data, headers).then(({data}) => data)



