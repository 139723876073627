import React from 'react'
import { useSelector } from "react-redux";
// data
import SecNav from './_secnav'
import PriNav from './_prinav'

export default function Header() {
  const INIT = useSelector(state => state.INIT)
  const {PRODUCT_CATEGORIES} = INIT
  
  return (
    <header className="header-main">
      <SecNav />
      <PriNav prodCate= {PRODUCT_CATEGORIES} />
    </header>
  )
}