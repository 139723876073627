import {API, ENDPOINTS} from '../apis'
import { resParser } from '../utils'

export const { SLIDER, ABOUT_US, LOCALE, CATEGORIES, PAYMENT_LIST, GALLERY, TERMS, POLICY, FOOTER_INFO, DELIVERY_INFO } = ENDPOINTS.PATH.GENERAL

export const SLIDE = data => API(SLIDER, data).then(res => resParser(res))
export const ABOUTUS = data => API(ABOUT_US, data).then(res => resParser(res))
export const LOCALIZATION = data => API(LOCALE, data).then(res =>  resParser(res))
export const PRODUCT_CATEGORIES = data => API(CATEGORIES, data).then(res => resParser(res))
export const PAYMENTS = data => API(PAYMENT_LIST, data).then(res => resParser(res))
export const DELIVERYINFO = data => API(DELIVERY_INFO, data).then(res => resParser(res))
export const PRODUCT_GALLERY = data => API(GALLERY, data).then(res => resParser(res))
export const TERMS_CONDITIONS = data => API(TERMS, data).then(res => resParser(res))
export const PRIVACY_POLICY = data => API(POLICY, data).then(res => resParser(res))
export const FOOTERINFO = data => API(FOOTER_INFO, data).then(res => resParser(res))

export const CTRL_ALL_GENERAL = {
  LOCALIZATION,
  SLIDE,
  ABOUTUS,
  PRODUCT_CATEGORIES,
  PAYMENTS,
  PRODUCT_GALLERY,
  TERMS_CONDITIONS,
  PRIVACY_POLICY,
  FOOTERINFO,
  DELIVERYINFO
}