import {API, ENDPOINTS} from '../apis'
const { SIGN_UP, OTP_VERIFY, OTP_RESEND, LOG_IN, FORGET_PASSWORD, USER_CHECK, GET_PROFILE, EDIT_PROFILE, RESET_PASSWORD, CUSTOMER_CITY, CONTACT_INFO } = ENDPOINTS.PATH.USER

export const SIGNUP = data => API(SIGN_UP, data).then(({data}) => data)

export const OTP = data => API(OTP_VERIFY, data).then(({data}) => data)

export const OTPRESEND = data => API(OTP_RESEND, data).then(({data}) => data)

export const FORGETPASSWORD = data => API(FORGET_PASSWORD, data).then(({data}) => data)

export const USERCHECK = data => API(USER_CHECK, data).then(({data}) => data)

export const SIGNIN = data => API(LOG_IN, null, data).then(({data}) => data)

export const GETPROFILE = (data, headers) => API(GET_PROFILE, data, headers).then(({data}) => data)

export const EDITPROFILE = (data, headers) => API(EDIT_PROFILE, data, headers).then(({data}) => data)

export const RESETPASSWORD = (data, headers) => API(RESET_PASSWORD, data, headers).then(({data}) => data)

export const CUSTOMERCITY = data => API(CUSTOMER_CITY, data).then(({data}) => data)

export const CONTACTINFO = data => API(CONTACT_INFO, data).then(({data}) => data)