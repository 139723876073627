import "bootstrap/scss/bootstrap.scss";
// react module
import React, {useEffect, useState} from "react";
// custom module
import AppRoute from "./routes/routes";
import Popup from "./components/popup";
// uitext
import "./i18n";
// style
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import { useDispatch, useSelector } from "react-redux";
import { CTRL_ALL_GENERAL } from "./controller";
import { init } from "./store/actions";
import Loading from "./components/loading";
const _ = require('underscore')

export default function App() {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const lang = useSelector(state => state.LOCALE)
  // fetching predefined data
  useEffect(() => {
    Promise.all([
      ..._.map(CTRL_ALL_GENERAL, item => item({
        lang
      }))
    ])
    .then(res => {
      dispatch(init(
        _.object([
        ..._.allKeys(CTRL_ALL_GENERAL)
        ], res)
      ))
      setLoading(false)
    })
  }, [lang, dispatch])

  if(loading)  {
    return (
      <Loading />
    )
  }

  return (
    <BrowserRouter>
      <div id="open-bugger-wrap"></div>
      <Popup />
      <Header />
      <AppRoute />
      <Footer />
    </BrowserRouter>
  );
}
