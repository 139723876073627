export default {
  data: [
    {
      img: require('../assets/img/banner1.jpg'),
      desc: 'You will get discount when you order by website'
    },
    {
      img: require('../assets/img/banner2.jpg'),
      desc: 'You will get discount when you order by website'
    },
    {
      img: require('../assets/img/banner3.jpg'),
      desc: 'You will get discount when you order by website'
    }
  ]
}