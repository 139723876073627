import { combineReducers } from 'redux'
import {ACT_INIT, ACT_LOCALE, ACT_PRODUCTS, OPEN_POPUP, ACT_USER, ACT_OTPVAL, ACT_PROFILE, ACT_PRODUCTDETAIL, ACT_MATERIAL, ACT_COLOR, ACT_ORDERLINE} from './actions'


function INIT(state = {}, {type, payload}) {
  switch (type) {
    case ACT_INIT:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}

function LOCALE(state = localStorage.getItem('LOCALE') || "en", {type, payload}) {
  switch (type) {
    case ACT_LOCALE:
      localStorage.setItem('LOCALE', payload)
      return payload
    default: 
      return state
  }
}

function PRODUCTS(state = [], {type, payload}) {
  switch(type) {
    case ACT_PRODUCTS:
      return payload
    default:
      return state
  }
}

function POPUP(state = false, {type, payload}) {
  switch (type) {
    case OPEN_POPUP:
      return payload
    default :
      return state
  }
}

function USER(state = JSON.parse(localStorage.getItem('user')), {type, payload}) {
  switch (type) {
    case ACT_USER:
      localStorage.setItem('user', JSON.stringify(payload))
      return payload
    default :
      return state
  }
}

function OTPVAL(state = JSON.parse(localStorage.getItem('otpval')), {type, payload}) {
  switch (type) {
    case ACT_OTPVAL:
      localStorage.setItem('otpval', JSON.stringify(payload))
      return payload
    default :
      return state
  }
}

function PROFILE(state = JSON.parse(localStorage.getItem('profile')), {type, payload}) {
  switch (type) {
    case ACT_PROFILE:
      localStorage.setItem('profile', JSON.stringify(payload))
      return payload
    default :
      return state
  }
}

function PRODUCTDETAIL(state = JSON.parse(localStorage.getItem('product_detail')), {type, payload}) {
  switch (type) {
    case ACT_PRODUCTDETAIL:
      localStorage.setItem('product_detail', JSON.stringify(payload))
      return payload
    default :
      return state
  }
}

function MATERIAL(state = JSON.parse(localStorage.getItem('material')), {type, payload}) {
  switch (type) {
    case ACT_MATERIAL:
      localStorage.setItem('material', JSON.stringify(payload))
      return payload
    default :
      return state
  }
}

function COLOR(state = JSON.parse(localStorage.getItem('color')), {type, payload}) {
  switch (type) {
    case ACT_COLOR:
      localStorage.setItem('color', JSON.stringify(payload))
      return payload
    default :
      return state
  }
}

function ORDERLINE(state = JSON.parse(localStorage.getItem('order_line')), {type, payload}) {
  switch (type) {
    case ACT_ORDERLINE:
      localStorage.setItem('order_line', JSON.stringify(payload))
      return payload
    default :
      return state
  }
}

const REDUCERS = combineReducers({
  INIT,
  LOCALE,
  PRODUCTS,
  POPUP,
  USER,
  OTPVAL,
  PROFILE,
  PRODUCTDETAIL,
  MATERIAL,
  COLOR,
  ORDERLINE
})

export {REDUCERS}